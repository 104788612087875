import React from "react";
import SidebarContent from "./SidebarContent";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import logoDark from "../../assets/images/kiru-logo-new.png";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu" style={{position: "fixed"}}>
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="30" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
