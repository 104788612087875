import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  InputGroup,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/kiru-logo-new.png"
import axiosInstance from "service/axiosConfig"
import Loader from "components/Common/loader"

const Register = props => {
  //meta title
  document.title = "Register | Kiru.ai"
  const [response, setResponse] = useState({})
  const [isLoading, SetIsLoading] = useState(false);
  const history = useNavigate()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
      company: "",
      password: "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Your Full Name"),
      email: Yup.string().required("Please Enter Your Email"),
      // phone: Yup.string().required("Please Enter Your Phone"),
      company: Yup.string().required("Please Enter Your Company"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      SetIsLoading(true);
      axiosInstance
        .post("/auth/register/", {
          email: values.email,
          first_name: values.full_name.split(" ")[0],
          last_name: values.full_name.split(" ").slice(1).join(" "),
          phone: values.phone,
          company: values.company,
          password: values.password,
        })
        .then(res => {
          SetIsLoading(false);
          const userEmail = res.data.email;
          history(`/login?userCreatedEmail=${userEmail}`);
          setResponse({
            success: [`User created with this email: ${userEmail}`],
          });
        })
        .catch(error => {
          SetIsLoading(false);
          setResponse({ errors: error.response.data.errors })
          setTimeout(() => setResponse({}), 4000)
        })
    },
  })

  // const user = state.Account.user;
  // const registrationError = state.Account.registrationError;
  // const loading = state.Account.loading;
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          {response && (
            <>
              {response.errors?.map((error, index) => (
                <Alert key={index} color="danger">
                  {error}
                </Alert>
              ))}
              {response.success?.map((success, index) => (
                <Alert key={index} color="success">
                  {success}
                </Alert>
              ))}
            </>
          )}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Kiru account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {!isLoading ? (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Full Name</Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="fas fa-user"></i>
                            </div>  
                            <Input
                              name="full_name"
                              type="text"
                              className="form-control rounded-0"
                              placeholder="Enter full name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.full_name || ""}
                              invalid={
                                validation.touched.full_name &&
                                validation.errors.full_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.full_name &&
                            validation.errors.full_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.full_name}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </div> 
                            <Input
                              id="email"
                              name="email"
                              className="form-control rounded-0"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Phone</Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="fas fa-phone-alt"></i>
                            </div> 
                            <Input
                              id="phone"
                              name="phone"
                              className="form-control rounded-0"
                              placeholder="Enter Phone number"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={
                                validation.touched.phone && validation.errors.phone
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Company</Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="fas fa-building"></i>
                            </div>
                            <Input
                              id="company"
                              name="company"
                              className="form-control rounded-0"
                              placeholder="Enter Company Name"
                              type="company"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.company || ""}
                              invalid={
                                validation.touched.company &&
                                validation.errors.company
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.company &&
                            validation.errors.company ? (
                              <FormFeedback type="invalid">
                                {validation.errors.company}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </div>
                            <Input
                              name="password"
                              type="password"
                              className="form-control rounded-0"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the Kiru{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                      </Form>
                    ) : (
                      <Loader loaderColor={"text-dark"} />
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Kiru.ai.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
