import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik, ErrorMessage } from "formik"
// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import axiosInstance from "service/axiosConfig"
import Loader from "components/Common/loader"
import { useLocation } from 'react-router-dom';

const Login = props => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userCreatedEmail = searchParams.get('userCreatedEmail');
  const history = useNavigate()
  //meta title
  document.title = "Login | Kiru.ai"
  const [errors, setErrors] = useState([]);
  const [isLoading, SetIsLoading] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      SetIsLoading(true);
      axiosInstance
        .post("/auth/login/", {
          email: values.email,
          password: values.password,
        })
        .then(res => {
          if(res.status === 200){
            SetIsLoading(false);
            localStorage.setItem("authUser", JSON.stringify(res.data))
            history("/home")
          }

        })
        .catch(error => {
          SetIsLoading(false);
          console.log(error, "this is the error")
          setErrors(error.response?.data?.errors)
          setTimeout(() => setErrors([]), 4000)
        })
    },
  })

  return (
    <React.Fragment>
      {userCreatedEmail && (
        <Alert key="" color="success">
        <p style={{textAlign: "center"}}>User created with this email: {userCreatedEmail}</p>
      </Alert>
      )}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          {errors &&
            errors.map((error, index) => (
              <Alert key={index} color="danger">
                {error}
              </Alert>
            ))}
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Kiru.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {isLoading ? (
                      <Loader loaderColor={"text-dark"} />
                    ): (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        {/* {errors &&
                          errors.map((error, index) => (
                            <ErrorMessage key={index} type="invalid">
                              {error}
                            </ErrorMessage>
                          ))} */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        {/*<div className="mt-4 text-center">*/}
                        {/*  <h5 className="font-size-14 mb-3">Sign in with</h5>*/}

                        {/*  <ul className="list-inline">*/}
                        {/*    <li className="list-inline-item">*/}
                        {/*      <Link*/}
                        {/*        to="#"*/}
                        {/*        className="social-list-item bg-primary text-white border-primary"*/}
                        {/*        onClick={e => {*/}
                        {/*          e.preventDefault()*/}
                        {/*          socialResponse("facebook")*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <i className="mdi mdi-facebook" />*/}
                        {/*      </Link>*/}
                        {/*    </li>*/}
                        {/*    <li className="list-inline-item">*/}
                        {/*      <Link*/}
                        {/*        to="#"*/}
                        {/*        className="social-list-item bg-danger text-white border-danger"*/}
                        {/*        onClick={e => {*/}
                        {/*          e.preventDefault()*/}
                        {/*          socialResponse("google")*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <i className="mdi mdi-google" />*/}
                        {/*      </Link>*/}
                        {/*    </li>*/}
                        {/*  </ul>*/}
                        {/*</div>*/}
                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Kiru.ai.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {}

export default withRouter(Login)
