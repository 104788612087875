import React, { useState, useEffect } from "react"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  ButtonGroup,
  UncontrolledTooltip,
  Card,
  CardBody,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Badge,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import JobApplicationAuthenticatedModal from "./JobApplicationAuthenticatedModal"
import ResumeModal from "./ResumeModal"
import axios from "axios"
import axiosInstance from "service/axiosConfig"
import { filter } from "lodash"
import JobDescriptionModal from "./JobDescriptionModal"

const CandidatesTableContainer = ({
  columns,
  data,
  jobTitle,
  searchQuery,
  setSearchQuery,
  currentPage,
  setCurrentPage,
  currentPageSize,
  setCurrentPageSize,
  totalPages,
  isResumesUploaded,
  setIsResumesUploaded,
  showSuccessMessage,
  setStatus,
  status,
  setFilterValue,
  currentJob,
  isStatusUpdate,
  setIsStatusUpdate,
    setSelectedDocumentUrl,
    setSelectedCandidated,
    setFilters,
    setStatuses,
    statuses,
    selectedCandidated,
    selectedDocumentUrl,
    toggleAddNewResume
}) => {
  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
    setCurrentPage(1)
  }

  const [openAccordion, setOpenAccordion] = useState([]);
  const [responseHeader, setResponseHeader] = useState([
    "Education",
    "Projects",
    "Relevancy",
    "Technical Skills",
    "Overall Score",
  ])
  const [isHovered, setIsHovered] = useState(false);
  const [statusSuccessMessage, setStatusSuccessMessage] = useState("");
  const [openDropdown, setOpenDropdown] = useState("")

  const navigate = useNavigate()
  const [addNewResumeModal, setAddNewResumeModal] = useState(false)
  const [jobDescriptionModal, setjobDescriptionModal] = useState(false)
  const [showResumeModal, setShowResumeModal] = useState(false)
  // const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("")
  // const [statuses, setStatuses] = useState([])
  // const [filters, setFilters] = useState([])
  // const [selectedCandidated, setSelectedCandidated] = useState([])
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  /// Update the click handler to toggle the collapse
const handleAccordionClick = (rowId) => {
  // Check if the rowId is already in the openAccordion array
  const isOpen = openAccordion.includes(rowId);

  // If it's open, close it; if it's closed, open it
  setOpenAccordion((prevOpenAccordion) =>
    isOpen
      ? prevOpenAccordion.filter((id) => id !== rowId)
      : [...prevOpenAccordion, rowId]
  );
};

  // const toggleAddNewResume = () => setAddNewResumeModal(!addNewResumeModal)
  const toggleShowResumeModal = () => setShowResumeModal(!showResumeModal)
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)
  const toggleShowJobDescription = () =>
    setjobDescriptionModal(!jobDescriptionModal)

  const closeDropdownOnClickOutside = e => {
    if (isDropdownOpen && e.target.closest(".dropdown") === null) {
      setDropdownOpen(false)
    }
  }
  const filteredStatuses = statuses.filter(status => status !== 'All');
  useEffect(() => {
    axiosInstance
      .get(`jobs/status`)
      .then(response => {
        setStatuses(["All"].concat(response.data.map(status => status.title)))
        setFilters(response.data.map(status => status.title))
      })
      .catch(error => {
        console.error("There was an error fetching the statuses", error)
      })
  }, [])

  useEffect(() => {
    document.addEventListener("click", closeDropdownOnClickOutside)
    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside)
    }
  }, [isDropdownOpen])

  const updateCandidatesStatus = value => {
    axiosInstance
      .put(`jobs/candidate_status`, {
        candidates: selectedCandidated,
        value: value,
      })
      .then(response => {
        setFilterValue(value)
      })
      .catch(error => {
        console.error("There was an error fetching the statuses", error)
      })
  }

  const updateCandidateStatus = (value, id) => {
    console.log('Updating status:', value, 'for row with ID:', id);
  axiosInstance
    .put(`jobs/candidate_status`, {
      candidates: [id], // Update only the selected candidate (specific row)
      value: value,
    })
    .then(response => {
      setFilterValue(value);
      setIsStatusUpdate(true);
      setTimeout(() => {
        setIsStatusUpdate(false);
      }, 5000);

      // You might want to update the status in your 'data' state as well
      // based on the response received from the server
    })
    .catch(error => {
      console.error("Error updating candidate status: ", error);
    });
};

  const buttonStyle = {
    textAlign: 'center',
    backgroundColor: isHovered ? '#556ee6' : '#D4DBF9',
    border: 'none',
    color: isHovered ? '#fff' : '#545AFB',
    fontSize: '15px',
  };

  const handleSelectAllRows = e => {
    if (e.target.checked) {
      setSelectedCandidated(data.map(row => row.id))
    } else {
      setSelectedCandidated([])
    }
  }
const iconCircleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px', // Adjust the size as needed
  height: '30px', // Adjust the size as needed
  borderRadius: '50%',
  border: '1px solid #b8b8b8', // Set the border color and width
  // Add any additional styling as needed
};
  const handleRowSelect = (e, id) => {
    console.log(id)
    if (e.target.checked) {
      setSelectedCandidated([...selectedCandidated, id])
    } else {
      setSelectedCandidated(
        selectedCandidated.filter(candidate => candidate != id)
      )
    }
  }
  console.log(isStatusUpdate)
  return (
    <div className="table-container">
      {/*{addNewResumeModal && (*/}
      {/*  <JobApplicationAuthenticatedModal*/}
      {/*    toggle={toggleAddNewResume}*/}
      {/*    modal={addNewResumeModal}*/}
      {/*    isResumesUploaded={isResumesUploaded}*/}
      {/*    setIsResumesUploaded={setIsResumesUploaded}*/}
      {/*  />*/}
      {/*)}*/}
      {showResumeModal && (
        <ResumeModal
          modal={showResumeModal}
          toggle={toggleShowResumeModal}
          documentUrl={selectedDocumentUrl}
        />
      )}

      {/*{jobDescriptionModal && (*/}
      {/*  <JobDescriptionModal*/}
      {/*    toggle={toggleShowJobDescription}*/}
      {/*    modal={jobDescriptionModal}*/}
      {/*    jobDescription={currentJob?.job_description}*/}
      {/*    jobTitle={currentJob?.title}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{showSuccessMessage && (*/}
      {/*  <div className="alert alert-success">Job created successfully!</div>*/}
      {/*)}*/}
      {/*{isResumesUploaded && (*/}
      {/*  <div className="alert alert-success">Resumes added successfully!</div>*/}
      {/*)}*/}
      {/* {isStatusUpdate && (*/}
      {/*  <div className="alert alert-success">Status has been updated</div>*/}
      {/*)}*/}
      {/*{showResumeModal && (*/}
      {/*  <ResumeModal*/}
      {/*    modal={showResumeModal}*/}
      {/*    toggle={toggleShowResumeModal}*/}
      {/*    documentUrl={selectedDocumentUrl}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<div className="d-flex justify-content-between py-4">*/}
      {/*  <div className="d-flex gap-4">*/}
      {/*    <button className="btn btn-primary" onClick={() => navigate("/home")}>*/}
      {/*        {"< " + jobTitle}*/}
      {/*      </button>*/}
      {/*      <button*/}
      {/*      className="btn"*/}
      {/*      style={buttonStyle}*/}
      {/*      onClick={toggleShowJobDescription}*/}
      {/*      onMouseEnter={() => setIsHovered(true)}*/}
      {/*      onMouseLeave={() => setIsHovered(false)}*/}
      {/*    >*/}
      {/*      View Job*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*  <button*/}
      {/*    className="btn btn-primary"*/}
      {/*    style={{*/}
      {/*      width: "160px",*/}
      {/*      padding: "0.375rem 0.75rem",*/}
      {/*      textAlign: "center",*/}
      {/*    }}*/}
      {/*    onClick={toggleAddNewResume}*/}
      {/*  >*/}
      {/*    Add New Resumes*/}
      {/*  </button>*/}
      {/*</div>*/}

      {/*<div className="d-flex gap-2 justify-content-between py-2">*/}
      {/*  <div className="d-flex gap-2">*/}
      {/*    <Input*/}
      {/*      type="text"*/}
      {/*      placeholder="Search..."*/}
      {/*      value={searchQuery}*/}
      {/*      onChange={handleSearchChange}*/}
      {/*      style={{*/}
      {/*        width: "250px",*/}
      {/*      }}*/}
      {/*      autoFocus={true}*/}
      {/*    />*/}

      {/*    <Input*/}
      {/*      className="form-control"*/}
      {/*      style={{*/}
      {/*        width: "fit-content",*/}
      {/*      }}*/}
      {/*      type="select"*/}
      {/*      value={status}*/}
      {/*      onChange={e => setStatus(e.target.value)}*/}
      {/*    >*/}
      {/*      {statuses.map(size => (*/}
      {/*        <option key={size} value={size}>*/}
      {/*          {size}*/}
      {/*        </option>*/}
      {/*      ))}*/}
      {/*    </Input>*/}
      {/*  </div>*/}
      {/*  <Dropdown*/}
      {/*    isOpen={isDropdownOpen}*/}
      {/*    toggle={toggleDropdown}*/}
      {/*    disabled={!selectedCandidated.length}*/}
      {/*    className={!selectedCandidated.length ? "d-none" : "d-block"}*/}
      {/*  >*/}
      {/*    <DropdownToggle*/}
      {/*      tag="button"*/}
      {/*      className="btn btn-warning"*/}
      {/*      style={{*/}
      {/*        padding: "6px 15px",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Change Status to <i className="mdi mdi-chevron-down" />*/}
      {/*    </DropdownToggle>*/}
      {/*    <DropdownMenu>*/}
      {/*      {filters.map(size => (*/}
      {/*        <DropdownItem*/}
      {/*          key={size}*/}
      {/*          onClick={() => updateCandidatesStatus(size)}*/}
      {/*        >*/}
      {/*          {size}*/}
      {/*        </DropdownItem>*/}
      {/*      ))}*/}
      {/*    </DropdownMenu>*/}
      {/*  </Dropdown>*/}
      {/*</div>*/}

      {data.length === 0 ? (
        <div className="text-center mt-5">
          <h4>No Candidates Found</h4>
          <button
              onClick={toggleAddNewResume}
              className="btn btn-primary me-1"
              style={{
                color: "white",
                fontWeight: "500",
                padding: "10px 18px",
                fontSize: "0.8rem",
                transition: "all 0.3s ease",
              }}
              onMouseOver={e => {
                e.target.style.backgroundColor = "#80A5FF"
                e.target.style.color = "white"
              }}
              onMouseOut={e => {
                e.target.style.backgroundColor = "#556ee6"
                e.target.style.color = "white"
              }}
            >
              <i className="fas fa-plus mx-1 text-white"></i>
              Add New Resume
          </button>
        </div>
      ) : (
        <>
          <div
            className="table-responsive"
            style={{
              minHeight: "50vh",
            }}
          >
            <Table className="table-bordered align-middle nowrap">
              <thead className="table-light">
                <tr>
                  <th
                    className="text-center font-weight-bold p-1"
                    style={{ fontSize: "1rem" }}
                  >
                    <input type="checkbox" onChange={handleSelectAllRows} />
                  </th>
                  {columns.map(column => (
                    <th
                      key={column.Header}
                      className="text-center font-weight-bold p-1"
                      style={{ fontSize: "1rem" }}
                    >
                      {column.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map(row => (
                  <>
                    <tr
                      key={row.id}
                      style={{
                        backgroundColor: openAccordion.includes(row.id) ? '#dae2ff' : 'inherit',
                      }}
                      className="text-center"
                    >
                      <td className="p-1 pb-0">
                        <input
                          type="checkbox"
                          onChange={e => handleRowSelect(e, row.id)}
                          checked={selectedCandidated.includes(row.id)}
                        />
                      </td>
                      <td
                          className="p-1"
                          style={{width: "500px", textAlign: "left"}}
                      >
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                              handleAccordionClick(row.id);
                            }}
                        >
                          <style>
                            {`
                              .hyperlink-style {
                                color: #495057; /* Change this to the desired default color */
                                /* Additional styles can be added here */
                              }
                              .hyperlink-style:hover {
                                color: blue; /* Change this to the desired color on hover */
                                /* Additional styles can be added here for hover effect */
                              }
                            `}
                          </style>
                          <a className="hyperlink-style">{row.name}</a>
                        </div>
                      </td>
                      <td
                          className="p-1"
                          style={{width: "300px", textAlign: "left"}}
                      >
                        {row.email}
                      </td>
                      <td
                          className="p-1"
                          style={{
                            position: "relative",
                            color: row.score?.total_score === 0 ? "blue" : "white",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            padding: "2px",
                            backgroundColor: row.score?.total_score === 0 ? "white" : `${row.score?.total_score_color || "blue"}`,
                            borderRadius: "4px",
                            width: "50px",
                          }}
                        >
                          {row.score?.total_score !== undefined ? (
                            row.score.total_score === 0 ? (
                              <i className="mdi mdi-loading mdi-spin" />
                            ) : (
                              row.score.total_score
                            )
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>{" "}

                      <td className="p-1" style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2px'
                      }}>
                      <div style={{ position: 'relative' }}>
                        <Dropdown
                          isOpen={openDropdown === row.id}
                          toggle={() => setOpenDropdown(openDropdown === row.id ? null : row.id)}
                        >
                          <DropdownToggle
                            style={{
                              backgroundColor: `${row.status?.color}`,
                              padding: '2px 8px',
                              color: 'white',
                              fontSize: '14px',
                              width: 'fit-content',
                              border: 0,
                            }}
                          >
                            {row.status?.title} <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {filteredStatuses.map((status) => (
                              <DropdownItem
                                key={status}
                                onClick={() => updateCandidateStatus(status, row.id)} // Pass the selected status and row ID
                              >
                                {status}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </td>

                      <td className="p-1">
                          <div className="d-flex align-items-center justify-content-center">
                            <div
                              className="cursor-pointer me-2"
                              style={iconCircleStyle}
                              onClick={() => {
                                setSelectedDocumentUrl(row.resume);
                                toggleShowResumeModal();
                              }}
                            >
                              <a className="mdi mdi-file-pdf font-size-16 text-danger me-1" style={{marginLeft:"14%"}} />
                            </div>
                            <div
                              className="cursor-pointer"
                              style={iconCircleStyle}
                              onClick={() => {
                                handleAccordionClick(row.id);
                              }}
                            >
                              <a  style={{marginLeft:"13%" , marginTop:"3px"}}
                                className={`mdi mdi-chevron-${(openAccordion || []).includes(row.id) ? 'up' : 'down'} font-size-18 text-success me-1`}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      {(openAccordion || []).includes(row.id) && (
                        <tr key={`accordion_${row.id}`}>
                          <td colSpan={6} style={{backgroundColor: "#F6F8FA"}}>
                            <div className="accordion" id="accordion">
                              <div className="accordion-item">
                                <Collapse isOpen={true} className="accordion-collapse">
                                  <div className="accordion-body" style={{backgroundColor: "#F6F8FA"}}>
                                    <div className="">
                                      <table className="table rounded-3" style={{backgroundColor:"white", }}>
                                        <thead>
                                          <tr>
                                            {responseHeader.map((h) => (
                                              <td key={h}>{h}</td>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>{row.response && row.response.education}</td>
                                            <td>{row.response && row.response.projects}</td>
                                            <td>{row.response && row.response.technical_skills}</td>
                                            <td>{row.response && row.response.relevancy}</td>
                                            <td
                                              style={{
                                                color: `${row.score.total_score_color}`,
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              {row.response && row.response.overall_score}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p className="my-2 rounded-3"  style={{backgroundColor:"white", padding:"10px", }}>
                                        <strong>Summary: </strong> {row.response && row.response.analysis_summary}
                                      </p>

                                      <div className="mt-3" style={{width:"100%"}}>
                                        <div className="row mt-2 justify-content-center">
                                          <div className="col-md-5  rounded-3" style={{width: "49%", backgroundColor:"white", }}>
                                            <div className="row mb-4 mt-3">
                                              <div className="col-md-3">
                                                <h5 style={{color:"#818898"}}>WORK EXPERIENCE</h5>
                                              </div>
                                              <div className="col-md-6">
                                                {/*<span className="border border-1 rounded-pill">*/}
                                                {/*  ・ {row.response && row.response.total_experience}*/}
                                                {/*</span>*/}
                                              </div>
                                              <div className="border-bottom mt-2"></div>
                                            </div>

                                            {
                                              row.response && row.response.work_experience_list ? (
                                                row.response.work_experience_list.map(work_exp => (
                                                  <div className="row mt-1" key={work_exp}>
                                                    <div className="col-md-6">
                                                      <b>{work_exp.organization_name}</b>
                                                      <p>{work_exp.designation}</p>
                                                    </div>
                                                    <div className="col-md-6" style={{ textAlign: "end" }}>
                                                      <p>{work_exp.start_date} - {work_exp.end_date}</p>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div className="row mt-1">
                                                  <div className="col-md-12">
                                                    <p>--</p>
                                                  </div>
                                                </div>
                                              )
                                            }
                                          </div>
                                          <div className="col-md-5  rounded-3" style={{width: "49%", marginLeft:"10px", backgroundColor:"white", }}>
                                            <div className="row mb-4 mt-3">
                                              <div className="col-md-4">
                                                <h5 style={{color:"#818898"}}>EDUCATION</h5>
                                              </div>
                                              <div className="border-bottom mt-2"></div>
                                            </div>
                                            {
                                              row.response && row.response.education_list ? (
                                                row.response.education_list.map(education => (
                                                  <div className="row mt-1" key={education}>
                                                    <div className="col-md-6">
                                                      <b>{education.institute_name}</b>
                                                      <p>{education.program}</p>
                                                    </div>
                                                    <div className="col-md-6" style={{ textAlign: "end" }}>
                                                      <p>{education.passing_year}</p>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div className="row mt-1">
                                                  <div className="col-md-12">
                                                    <p>--</p>
                                                  </div>
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>
                                        <div className="row mt-2 justify-content-center">
                                          <div className="col-md-5  rounded-3" style={{width:"49%", backgroundColor:"white", }}>
                                            <div className="row mb-4 mt-3">
                                              <div className="col-md-3">
                                                <h5 style={{color:"#818898"}}>SKILLS</h5>
                                              </div>
                                              <div className="col-md-3">
                                                <span className="border border-1 rounded-pill">
                                                  ・ {row.response && row.response.skills_list && row.response.skills_list.length > 0 ? (
                                                          <>{row.response.skills_list.length} Skills</>
                                                        ) : (
                                                          <>0 Skills</>
                                                        )}
                                                </span>
                                              </div>
                                              <div className="border-bottom mt-2"></div>
                                            </div>
                                            <div className=" mt-1" style={{marginTop: "35px"}}>
                                              {
                                                row.response && row.response.skills_list && row.response.skills_list.length > 0 ? (
                                                  row.response.skills_list.map((skill, index) => (
                                                    <a key={index} className="badge bg-success me-2">
                                                      ・ {skill}
                                                    </a>
                                                  ))
                                                ) : (
                                                  <span>--</span>
                                                )
                                              }
                                            </div>
                                          </div>
                                          <div className="col-md-5  rounded-3" style={{width:"49%", marginLeft:"10px", backgroundColor:"white", }}>
                                            <div className="row mb-4 mt-3">
                                              <div className="col-md-4">
                                                <h5 style={{color:"#818898"}}>CONTACT INFO</h5>
                                              </div>
                                              <div className="border-bottom mt-2"></div>
                                            </div>
                                            <div className="row mt-1">
                                              <div className="col-md-6">
                                                <b>Phone Number</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.phone_number ? row.response.contact_info.phone_number : "--"}</p>
                                              </div>
                                              <div className="col-md-6">
                                                <b>E-Mail</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.email ? row.response.contact_info.email : "--"}</p>
                                              </div>
                                            </div>

                                            <div className="row mt-1">
                                              <div className="col-md-12">
                                                <b>Street Address</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.address ? row.response.contact_info.address : "--"}</p>
                                              </div>
                                            </div>

                                            <div className="row mt-1">
                                              <div className="col-md-6">
                                                <b>Postal Code</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.postal_code ? row.response.contact_info.postal_code : "--"}</p>
                                              </div>
                                              <div className="col-md-6">
                                                <b>City</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.city ? row.response.contact_info.city : "--"}</p>
                                              </div>
                                            </div>

                                            <div className="row mt-1">
                                              <div className="col-md-6">
                                                <b>Region</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.region ? row.response.contact_info.region : "--"}</p>
                                              </div>
                                              <div className="col-md-6">
                                                <b>Country</b>
                                                <p>{row.response && row.response.contact_info && row.response.contact_info.country ? row.response.contact_info.country : "--"}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                  </>
                ))}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center mt-5">
            <Col>
              <Input
                className="form-control"
                style={{
                  width: "100px",
                }}
                type="select"
                value={currentPageSize}
                onChange={e => setCurrentPageSize(Number(e.target.value))}
              >
                {[10, 15, 20, 25].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </Input>
            </Col>
            <Col className="col-ml-auto d-flex align-items-center">
              <div style={{ fontSize: "0.9rem" }}>
                Showing page <strong>{currentPage}</strong> of{" "}
                <strong>{totalPages}</strong>
              </div>
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  className="rounded-circle"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: currentPage === 1 ? "#E0E0E0" : "",
                    borderColor: "transparent",
                  }}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto">
              <Button
                className="rounded-circle"
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#3498db", // Set background color to blue
                  borderColor: "transparent",
                  color: "#fff", // Set font color to white
                }}
                disabled
              >
                {currentPage}
              </Button>
            </Col>
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  className="rounded-circle"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor:
                      currentPage === totalPages ? "#E0E0E0" : "",
                    borderColor: "transparent",
                  }}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {">"}
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default CandidatesTableContainer