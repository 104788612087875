import React from "react"
import { Navigate } from "react-router-dom"

// Pages Component

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Jobs
import JobsTable from "../pages/Jobs/JobsTable"
import JobWorkflow from "../pages/TalentPoolEvaluation/index"

// Jobs -> Candidates
import CandidatesTable from "pages/Candidates/CandidatesTable"

import UiProgressbar from "../pages/TalentPoolEvaluation/index"
import JobApplication from "pages/TalentPoolEvaluation/JobApplication"
import Pages500 from "pages/Utility/pages-500"
import Pages404 from "pages/Utility/pages-404"
import ResponseJobApplication from "pages/TalentPoolEvaluation/Reponse"
import Comingsoon from "components/Common/ComingSoon"

const authProtectedRoutes = [
  { path: "/profile", component: <UserProfile /> },
  { path: "/home", component: <JobsTable /> },
  { path: "/view_candidates/:job_id", component: <CandidatesTable /> },
  { path: "/add_new_job", component: <JobWorkflow /> },
  { path: "/comingsoon", component: <Comingsoon />},
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/job_application/:job_id", component: <JobApplication /> },
  {path: "/job_application_response", component: <ResponseJobApplication /> },
  { path: "/page404", component: <Pages404 /> },
  { path: "/page500", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }
